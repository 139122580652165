import images from "../specs/pdf-mentalhealth-images.js";
import {banners, logos} from "../specs/pdf-common.js";

const people = {
		"student-resilience": [
			{
				image: "andrew-wicking.png",
				texts: [
					"Dr Andrew Wicking",
					"Director",
					"Resilient Youth Australia",
					"Adjunct Research Associate",
					"University of South Australia",
				],
			},
			{
				image: "mark-kohler.png",
				texts: [
					"Dr Mark Kohler",
					"Senior Lecturer in Psychology",
					"Director, WiLD Lab",
					"University of Adelaide",
				],
			},
			{
				image: "jill-dorrian.png",
				texts: [
					"Professor Jill Dorrian",
					"Professor in Psychology",
					"University of South Australia",
				],
			},
			{
				image: "kurt-lushington.png",
				texts: [
					"Professor Kurt Lushington",
					"Professor in Psychology",
					"University of South Australia",
				],
			},
		],
		"phillips-foundation": [],
		"workplace-resilience": [],
	},
	frontmatter = {
		"student-resilience": {
			title: "Welcome to your Mental Health Report",
			subtitle: "Student Resilience Survey",
			content_RY: [
				{
					columns: [
						{
							stack: [
								{
									text: "Since 2013, Resilient Youth Australia have surveyed more than 900,000 Australian school students, aged 7-18 years, using international gold standard mental health scales.",
									style: "welcomeParagraph"
								},
								{
									text: [
										"This work has resulted in a peer-reviewed publications, such as Sophie Richter et al, “Children’s Hope Scale: Factor Structure and Norms for Australi c an Children Aged 8–18 Years” (2024) 76(1) ", 
										{ text: "Australian Journal of Psychology. ", italics: true },
										"DOI: ",
										{ text: "https://doi.org/10.1080/00049530.2024.2322724", link: "https://doi.org/10.1080/00049530.2024.2322724", color: "blue" },
										"."
									],
									style: "welcomeParagraph",
								},
								{
									text: "This report presents all your student mental health data in one place.",
									style: "welcomeParagraph",
								},
								{
									text: [
										"It includes six (6) gold standard measures of mental health, each reported by year level and gender against Australian Norms. The six measures reported are:"
									],
									style: "welcomeParagraph",
								},
								{
									columns: [
										{
											ul: [ "Life Satisfaction", "Hope" ],
											style: "welcomeParagraph",
											width: "auto"
										},
										{
											ul: [ "Anxiety", "Depression" ],
											style: "welcomeParagraph",
											width: "auto"
										},
										{
											ul: [ "Coping", "Connection to Nature" ],
											style: "welcomeParagraph",
											width: "*"
										}
									],
									columnGap: 20,
								},
								{
									text: [
										"For each of these measures we report the ‘construct view’, and where multiple items make up the construct. We also report each of these items separately in your ",
										{ text: "online interactive portal", link: "[[PORTAL]]", color: "blue" },
										".",
									],
									style: "welcomeParagraph",
								},
								{
									text: [
										"In addition, our university partners have developed three key measures presented in scatterplot format:",
									],
									style: "welcomeParagraph",
								},
								{
									ul: [
										"Resilience Score (Life Satisfaction & Hope)",
										"Healthy Mind Score (Anxiety & Depression)",
										"Ready to Learn Score (Anxiety & Coping)",
									],
									style: "welcomeParagraph"
								},
								{
									text: [
										"We are here to support you with any aspect of this report — please don't hesitate to email us at ",
										{
											text: "helpdesk@resilientyouth.org",
											link: "mailto:helpdesk@resilientyouth.org",
											color: "blue",
										},
										".",
									],
									style: "welcomeParagraph",
								},
								{
									text: "We trust that the data in this report provides a clear understanding of your students' mental health, and will help you to develop strategies to assist the learning and positive development of your students.",
									style: "welcomeParagraph",
								},
								[
									{
										text: "Best wishes,",
										style: "welcomeParagraph",
									},
									{
										image: "signature.png",
										width: 80,
										margin: [0, -4, 0, 6],
									},
									{
										text: "Dr Andrew Wicking",
										style: "welcomeParagraph",
										italics: true,
										margin: [0, 0, 0, 5],
									},
								],
							],
							width: "75%",
							margin: [0, 40, 10, 0],
						},
						{
							table: {
								widths: Array(2).fill('50%'),
								body: Array.from({length: Math.ceil(people["student-resilience"].length/2)}, (_,rowIndex)=> 
								Array.from({length: 2}, (_, colIndex)=>{
									const index = rowIndex * 2 + colIndex;
									const person = index < people["student-resilience"].length ?people["student-resilience"][index] : {}; 
									return person.image ? {
										stack:[
											{
												image: person.image,
												fit: [90,90]
											},
											person.texts.map((text, idx) => ({
												text: text,
												style: "paragraph",
												font: "Arial",
												fontSize: 8,
												margin: [0, (idx == 0) || (idx == 1) ? 5 : 0, 0, (idx === person.texts.length - 1) ? 5 : 0],
												bold: idx == 0,
												italics: idx != 0,
											})),
										]
									} : {};
									})
								)
							},
							width: "25%",
							margin: [0, 40, 0, 0],
							layout: 'noBorders'
						},
					]
				}
				/* {
					table: {
						widths: ["*"],
						body: [
							[
								{
									text: [
										"The Student Resilience Survey does ",
										{ text: "NOT", bold: true },
										" identify individual students; diagnose individual students; assess success or failure;\n compare individual students, classrooms, or schools. All data is anonymous, non-identified and aggregated.",
									],
									fillColor: "#f6f6f6",
									alignment: "center",
									// font: "MetropolisWeb",
									fontSize: 9,
									lineHeight: 1.05,
									bold: false,
								},
							],
						],
					},
					layout: 'disclaimer'
				} */
			],
			content: [
				{
					columns: [
						[
							{
								text: "Since 2013, Resilient Youth Australia have surveyed more than 900,000 young Australian school students, aged 7-18 years, using international gold standard mental health scales.",
								style: "paragraph"
							},
							{
								text: [
									"This work has resulted in a peer-reviewed publications, such as Sophie Richter et al, “Children’s Hope Scale: Factor Structure and Norms for Australian Children Aged 8–18 Years” (2024) 76(1) ' ", 
									{ text: "Australian Journal of Psychology ", italics: true },
									"71:2, DOI: ",
									{ text: "https://doi.org/10.1080/00049530.2024.2322724.", link: "https://doi.org/10.1080/00049530.2024.2322724.", color: "blue" },
									"."
								],
								style: "paragraph",
							},
							{
								text: "This report presents all your student mental health data in one place.",
								style: "paragraph",
							},
							{
								text: [
									"It includes six (6) gold standard measures of mental health, each reported by year level and gender against Australian Norms. The six measures reported are:"
								],
								style: "paragraph",
							},
							{
								columns: [
									{
										ul: [ "Life Satisfaction", "Hope" ],
										style: "paragraph",
										width: "auto"
									},
									{
										ul: [ "Anxiety", "Depression" ],
										style: "paragraph",
										width: "auto"
									},
									{
										ul: [ "Coping", "Connection to Nature" ],
										style: "paragraph",
										width: "*"
									}
								],
								columnGap: 20,
							},
						],
						[
							{
								text: [
									"For each of these measures we report the ‘construct view’, and where multiple items make up the construct. We also report each of these items separately in your ",
									{ text: "online interactive portal", link: "[[PORTAL]]", color: "blue" },
									".",
								],
								style: "paragraph",
							},
							{
								text: [
									"In addition, our university partners have developed three key measures presented in scatterplot format:",
								],
								style: "paragraph",
							},
							{
								ul: [
									"Resilience Score (Life Satisfaction & Hope)",
									"Healthy Mind Score (Anxiety & Depression)",
									"Ready to Learn Score (Anxiety & Coping)",
								],
								style: "paragraph"
							},
							{
								text: [
									"We are here to support you with any aspect of this report — please don't hesitate to email us at ",
									{
										text: "helpdesk@resilientyouth.org",
										link: "mailto:helpdesk@resilientyouth.org",
										color: "blue",
									},
									".",
								],
								style: "paragraph",
							},
							{
								text: "We trust that the data in this report provides a clear understanding of your students' mental health, and will help you to develop strategies to assist the learning and positive development of your students.",
								style: "paragraph",
							},
						],
					],
					columnGap: 20,
					margin: [0, 0, 0, 0],
				},
				{
					columns: people["student-resilience"].map(
						({ image, texts }, idx) => ({
							stack: [
								{
									image: image,
									fit: [80, 80],
									alignment: "left",
									margin: [0, 0, 0, 10],
								},
								texts.map((text, idx) => ({
									text: text,
									style: "paragraph",
									fontSize: 8,
									margin: [0, (idx % 2 == 1) & (text != "University of Adelaide") ? 3 : 0, 0, 0],
									bold: idx == 0,
								})),
							],
							width: "auto",
							alignment: "left",
						})
					),
					columnGap: 20,
					margin: [0, 15, 10, 10],
				},
				{
					table: {
						widths: ["*"],
						body: [
							[
								{
									text: [
										"The Student Resilience Survey does ",
										{ text: "NOT", bold: true },
										" identify individual students; diagnose individual students; assess success or failure;\n compare individual students, classrooms, or schools. All data is anonymous, non-identified and aggregated.",
									],
									fillColor: "#f6f6f6",
									alignment: "center",
									// font: "MetropolisWeb",
									fontSize: 9,
									lineHeight: 1.05,
									bold: false,
								},
							],
						],
					},
					layout: 'disclaimer'
				}
			]
		},
		"phillips-foundation": {
			title: "Welcome to your TOMS Report",
			content: {
				columns: [
					[
						{
							text: [
								"Since 2017, The Phillips Foundation has been actively supporting charitable organisations and projects around the world to deliver holistic education opportunities to remote or disadvantaged communities.",
							],
							style: "paragraph",
						},
						{
							text: [
								"Survey responses seek to provide us with an indication on how we are progressing against our broad goal of supporting children and youth. It includes two (2) gold standard measures of mental health and one measure of connection to nature, each reported by year level and gender. For each of these measures we report the 'construct view', and where multiple items make up the construct, we also report each of these items separately in your interactive ",
								{ text: "portal", link: "[[PORTAL]]", color: "blue" },
								".",
							],
							style: "paragraph",
						},
						{
							text: "The two gold standard measures and one measure of connection to nature reported in this Annual Outcomees Survey Report are:",
							style: "paragraph",
						},
						{
							columns: [
								"Hope",
								"Life Satisfaction",
								"Connection to Nature",
							].reduce((res, val, ind, arr) => {
								if (ind % 3 == 0)
									res.push({
										ul: arr.slice(ind, ind + 3),
										style: "paragraph",
									});
								return res;
							}, []),
							columnGap: 20,
							margin: [0, 0, 0, 10],
						},
						{
							text: [
								{ text: "1", sup: { offset: "30%", fontSize: 9 } },
								'See, for example, Centofanti et al. (2019) "Establishing norms for mental well-being in young people (7-19 years) using the General Health Questionnaire-12." ',
								{ text: "Australian Journal of Psychology ", italics: true },
								" 71:2, DOI: ",
								{
									text: "10.1111/ajpy.12227",
									link: "https://doi.org/10.1111/ajpy.12227",
									color: "blue",
								},
							],
							style: "paragraph",
							alignment: "right",
							fontSize: 8,
							margin: [0, 20, 0, 0],
						},
					],
					[
						{
							text: [
								"The Phillips Foundation are committed to supporting its strategic partners share their impact and outcomes. We use this data to assess our own progress of our stratgey and make future funding decisions. We encourage you to consider how this data can help amplify the message and impact to your other supporters. please don’t hesitate to email us at ",
								{
									text: "enquiries@thephillipsfoundation.org.au",
									link: "mailto:enquiries@thephillipsfoundation.org.au",
									color: "blue",
								},
								".",
							],
							style: "paragraph",
						},
						{
							columns: people["phillips-foundation"].map(
								({ image, texts }, idx) => ({
									stack: [
										{
											image: image,
											fit: [100, 100],
											alignment: "left",
											margin: [0, 0, 0, 10],
										},
										texts.map((text, idx) => ({
											text: text,
											style: "paragraph",
											fontSize: 9,
											margin: [0, idx % 2 == 1 ? 5 : 0, 0, 0],
										})),
									],
									width: idx <= 1 ? "*" : "auto",
									alignment: "left",
								})
							),
							columnGap: 5,
							margin: [0, 20, 0, 10],
						},
						{
							table: {
								widths: ["*"],
								body: [
									[
										{
											text: [
												"The TOMS Survey does ",
												{ text: "NOT", bold: true },
												" identify individual respondents; diagnose individual respondents; assess success or failure; compare individual respondents or communities. All data is anonymous, non-identified and aggregated.",
											],
											fillColor: "#F5F5F5",
											fillOpacity: 0.75,
											alignment: "left",
											font: "MetropolisWeb",
											fontSize: 9,
											lineHeight: 1.05,
											bold: false,
										},
									],
								],
							},
							layout: {
								hLineWidth: function (i, node) {
									return 0;
								},
								vLineWidth: function (i, node) {
									return 0;
								},
								hLineColor: function (i, node) {
									return "#fedb4a";
								},
								vLineColor: function (i, node) {
									return "#fedb4a";
								},
								paddingLeft: function (i, node) {
									return 5;
								},
								paddingRight: function (i, node) {
									return 5;
								},
								paddingTop: function (i, node) {
									return 5;
								},
								paddingBottom: function (i, node) {
									return 5;
								},
							},
							margin: [0, 82, 0, 0],
						},
					],
				],
				columnGap: 20,
				margin: [0, 0, 0, 0],
			},
		},
		"workplace-resilience": {
			title: "Survey Background",
			content: [
				{
					columns: [
						{
							width: "60%",
							stack: [
								{
									text: "The Workplace Resilience Survey was developed in collaboration with the Department of Psychology, Social Work and Social Policy at the University of South Australia.",
									style: "paragraph",
								},
								{
									text: "The Workplace Resilience Survey is designed to establish a scientific evidence base to assist you work towards the best working culture possible and to focus attention on specific opportunities to improve staff wellbeing. ",
									style: "paragraph",
								},
							]
						},
						[
							{
								image: "logoLarge",
								width: 120,
								alignment: "center",
								margin: [-250, -35, 0, 0],
							},
						]
					],
					columnGap: 10,
				},
				{
					text: "This is important for two reasons: firstly because the well-being of your staff is important for its own sake, and secondly, because unhappy or anxious staff will adversely impact the performance of your organisation.",
					style: "paragraph",
				},
				{
					text: "The Workplace Resilience Survey puts in your hands up-to-the-minute data on the resilience, mental health and well-being of all staff (non-identified) in your organisation, across the 3 domains of Self, Job and Workplace.",
					style: "paragraph",
				},
				{
					text: "Following the completion of the survey, we provide you with the results through various university-developed reports, before co-designing a range of wellbeing supports through presentations, workshops, coaching and ongoing consulting.",
					style: "paragraph",
				},
				{
					text: "We know that you will find your Workplace Resilience Survey data insightful and a practical starting point for your intentional efforts around staff wellbeing. ",
					style: "paragraph",
				},
				{
					text: "Please don't hesitate to contact us if we can be of further assistance.",
					style: "paragraph",
				},
				{
					text: "Kind Regards,",
					style: "paragraph",
				},
				{
					text: "Peter Seehusen",
					bold: true,
					style: "paragraph",
					margin: [0, 0, 0, 0],
				},
				{
					text: "Director",
					style: "paragraph",
					margin: [0, 0, 0, 0],
				},
				{
					text: "+44 (0) 7480 259 155",
					style: "paragraph",
					link: "tel:+44 (0) 7480 259 155",
					margin: [0, 0, 0, 0],
				},
				{
					text: "hello@leadwellcc.com",
					style: "paragraph",
					color: "#45a6dd",
					link: "hello@leadwellcc.com",

					margin: [0, 0, 0, 0],
				},
				{
					text: "www.leadwellcc.com",
					style: "paragraph",
					color: "#45a6dd",
					link: "www.leadwellcc.com",
					margin: [0, 0, 0, 0],
				},
				{
					table: {
						widths: ["*"],
						body: [
							[
								{
									text: [
										"The Workplace Resilience Survey does ",
										{ text: "NOT", bold: true },
										" identify individual staff members; diagnose individual staff members; assess success or failure; compare individual staff members or organisations.",
									],
									fillColor: "#50a0ff",
									alignment: "center",
									font: "MetropolisWeb",
									bold: false,
								},
							],
						],
					},
					layout: "disclaimer",
					margin: [0, 20, 0, 0],
				},
			],
		}
	},
	cover = {
		"student-resilience": {
			logoAndTitle: [
				{
					image: "logoLarge",
					width: 200,
					alignment: "center",
					margin: [0, 10, 0, 50],
				},
				{
					text: `STUDENT RESILIENCE SURVEY`,
					fontSize: 12,
					alignment: "center",
					bold: false,
					margin: [0, 0, 0, 10],
				},
				{
					text: "Mental Health Report",
					fontSize: 23,
					alignment: "center",
				},
			],
			logoAndTitleRY: [
				{
					columns:[
						{
							stack:[
								{
									text: `STUDENT RESILIENCE SURVEY`,
									font: "Arial",
									fontSize: 8,
									alignment: "left",
									bold: false,
									margin: [0, 0, 0, 10],
								},
								{
									text: "Mental Health Report",
									fontSize: 24,
									alignment: "left",
								},
							]
						},
						{
							image: logos["ryaWhiteLogo"],
							width: 80,
							alignment: "right",
							margin: [0, -10, 0, 0],
						},
					],
					width: ['50%', '50%'],
					style: {
						color: "white",
					}
				},
			],
			email: [
				{
					text: "helpdesk@resilientyouth.org",
					alignment: "center",
					fontSize: 14,
					margin: [0, 100, 0, 0],
				},
			]
		},
		"phillips-foundation": {
			logoAndTitle: [
				{
					image: "logoLarge",
					width: 200,
					alignment: "center",
					margin: [0, 10, 0, 50],
				},
				{
					text: `TOMS REPORT`,
					fontSize: 12,
					alignment: "center",
					bold: false,
					margin: [0, 0, 0, 10],
				},
				{
					text: "TOMS Report",
					fontSize: 23,
					alignment: "center",
				},
			],
			email: [
				{
					text: "",//"enquiries@thephillipsfoundation.org.au",
					alignment: "center",
					fontSize: 14,
					margin: [0, 100, 0, 0],
				},
			]
		},
		"workplace-resilience": {
			logoAndTitle: [
				{
					image: "logoLarge",
					width: 180,
					alignment: "center",
					margin: [0, 50, 0, 70],
				},
				{
					text: "WORKPLACE RESILIENCE SURVEY",
					fontSize: 14,
					alignment: "center",
					bold: false,
					margin: [0, 0, 0, 15],
				},
				{
					text: "Mental Health Report",
					fontSize: 28,
					alignment: "center",
				},
			],
			email: [
				{
					text: "hello@leadwellcc.com",
					alignment: "center",
					fontSize: 14,
					margin: [0, 45, 0, 0],
				},
			],
		}
	},
	pages = {
		student: [
			{
				key: "cantril",
				surveys: ["student-resilience", "phillips-foundation"],
				graph: "stacked",
				classes: [
					{
						id: 8,
						label: "8 (best)",
						colour: "#388e3c",
					},
					{
						id: 7,
						label: "7",
						colour: "#43a047",
					},
					{
						id: 6,
						label: "6",
						colour: "#7cb342",
					},
					{
						id: 5,
						label: "5",
						colour: "#c0ca33",
					},
					{
						id: 4,
						label: "4",
						colour: "#fdd835",
					},
					{
						id: 3,
						label: "3",
						colour: "#ffb300",
					},
					{
						id: 2,
						label: "2",
						colour: "#fb8c00",
					},
					{
						id: 1,
						label: "1 (worst)",
						colour: "#f4511e",
					},
				],
				threshold: 5,
				classify: function (x) {
					return Number(x);
				},
				title: "Overall Life Satisfaction",
				subtitle: "Cantril Self Anchoring Scale (Cantril Ladder)",
				description:
					"This graph shows the reported levels of overall life satisfaction among your respondents.",
				appendix: {
					question:
						"Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents the best possible life for you and the bottom represents the worst possible life for you. On which step of the ladder do you feel you stand at this time?",
				},
			},
			{
				key: "CHS",
				surveys: ["student-resilience", "phillips-foundation"],
				graph: "stacked",
				classes: [
					{
						id: 6,
						label: "All",
						colour: "#43a047",
					},
					{
						id: 5,
						label: "Most",
						colour: "#7cb342",
					},
					{
						id: 4,
						label: "Lots",
						colour: "#c0ca33",
					},
					{
						id: 3,
						label: "Some",
						colour: "#fdd835",
					},
					{
						id: 2,
						label: "Little",
						colour: "#ffb300",
					},
					{
						id: 1,
						label: "None",
						colour: "#f4511e",
					},
				],
				threshold: 4,
				classify: function (x) {
					if (x >= 5.5) {
						return 6;
					} else if (x >= 4.5) {
						return 5;
					} else if (x >= 3.5) {
						return 4;
					} else if (x >= 2.5) {
						return 3;
					} else if (x >= 1.5) {
						return 2;
					} else {
						return 1;
					}
				},
				title: "Hope",
				subtitle: "Children’s Hope Scale (CHS)",
				description:
					"This graph shows the varying degrees of hope reported among your respondents.",
				appendix: {
					question: "In most situations,",
					options: [
						"I think I am doing pretty well.",
						"I can think of many ways to get things in life that are most important to me.",
						"I am doing just as well as other kids my age.",
						"When I have a problem, I can come up with lots of ways to solve it.",
						"I think the things I have done in the past will help me in the future.",
						"Even when others want to quit, I know that I can find ways to solve the problem.",
					],
				},
			},
			{
				key: "GAD-2",
				surveys: ["student-resilience"],
				graph: "stacked",
				classes: [
					{
						id: 2,
						label: "Not Anxious",
						colour: "#66bc62",
					},
					{
						id: 1,
						label: "Anxious",
						colour: "#f36c42",
					},
				],
				threshold: 2,
				classify: function (x) {
					if (x >= 3) {
						return 1;
					} else {
						return 2;
					}
				},
				title: "Anxiety",
				subtitle: "Generalized Anxiety Disorder (GAD-2)",
				description:
					"This graph shows the percentage of your respondents who report symptoms of anxiety.",
				appendix: {
					question:
						"Over the last 2 weeks, how often have you been bothered by the following problems?",
					options: [
						"Feeling nervous, anxious, or on edge.",
						"Not being able to stop or control worrying.",
					],
				},
			},
			{
				key: "PHQ-2",
				surveys: ["student-resilience"],
				graph: "stacked",
				classes: [
					{
						id: 2,
						label: "Not Depressed",
						colour: "#66bc62",
					},
					{
						id: 1,
						label: "Depressed",
						colour: "#f36c42",
					},
				],
				threshold: 2,
				classify: function (x) {
					if (x >= 3) {
						return 1;
					} else {
						return 2;
					}
				},
				title: "Depression",
				subtitle: "Patient Health Questionnaire (PHQ-2)",
				description:
					"This graph shows the percentage of your respondents who report symptoms of depression.",
				appendix: {
					question:
						"Over the last 2 weeks, how often have you been bothered by the following problems?",
					options: [
						"Feeling down, depressed or hopeless.",
						"Little interest or pleasure in doing things.",
					],
				},
			},
			{
				key: "CSI-4",
				surveys: ["student-resilience"],
				graph: "stacked",
				title: "Coping Strategies Inventory (4 item) [adapted]",
				classes: [
					{
						id: 2,
						label: "Engaged",
						colour: "#66bc62",
					},
					{
						id: 1,
						label: "Disengaged",
						colour: "#f36c42",
					},
				],
				threshold: 2,
				classify: function (x) {
					if (x >= 4) {
						return 1;
					} else {
						return 2;
					}
				},
				title: "Disengaged Coping Strategies",
				subtitle:
					"Coping Strategies Inventory [disengagement subscale] (CSI-4)",
				description:
					"This graph shows the reported percentage of your students who are disengaged from problem-solving.",
				appendix: {
					options: [
						"When I have a problem, I avoid doing anything about it.",
						"When I have a problem, I just wish it would go away.",
						"When bad things happen, I usually blame myself.",
						"When bad things happen, I talk to others about my problem.",
					],
				},
			},
			{
				key: "resilience",
				surveys: [
					"student-resilience",
					"short-resilience",
					"relationships-and-no-bullying",
				],
				shade: "#bcaaa4",
				graph: "scatter",
				scales: ["rCHS", "rCantril"],
				xMeasure: "Dissatisfied",
				xMin: 1,
				xMax: 8,
				xThresh: 4.5,
				yMeasure: "Unhopeful",
				yMin: 1,
				yMax: 6,
				yThresh: 3.5,
				reverseX: true,
				reverseY: true,
				quarters: {
					leftbottom: "RESILIENT",
					rightbottom: "MODERATELY RESILIENT",
					lefttop: "MODERATELY RESILIENT",
					righttop: "LESS RESILIENT",
				},
				quarters_sub: {
					leftbottom: "High Hope & High Life Satisfaction",
					rightbottom: "High Hope & Low Life Satisfaction",
					lefttop: "Low Hope & High Life Satisfaction",
					righttop: "Low Hope & Low Life Satisfaction",
				},
				title: "Resilience Score",
				subtitle: "Hope and Life Satisfaction: CHS and Cantril",
				description:
					"This scatterplot represents each respondent with a single dot. The position of each dot indicates each students reported levels of hope, life satisfaction. The presence of both hope and life satisfaction is a good indicator of resilience.",
				explainer: [
					{
						columns: [
							[
								{
									text: "This scatterplot is a key indicator of the resilience of your students as measured by two positive components of mental health – Life Satisfaction (Cantril Ladder) and Hope (Children’s Hope Scale).",
									style: "paragraph"
								},
								{
									text: "On this scatterplot each student is represented with a single dot. The position of each dot indicates each student’s reported life satisfaction and hopefulness. Students with high life satisfaction and a high level of hope are categorised as ‘Resilient’, in contrast to students with low-levels of hope and low-levels of life satisfaction who are categorised as ‘Less Resilient’.",
									style: "paragraph"
								},
							],
							[
								{
									text: "In other words, appropriate thresholds allow students to be categorised by whether they are:",
									style: "paragraph"
								},
								{
									ol: [
										"RESILIENT = “High Hope & High Life Satisfaction”",
										"MODERATELY RESILIENT = “Low Hope & High Life Satisfaction”",
										"MODERATELY RESILIENT = “High Hope & Low Life Satisfaction”",
										"LESS RESILIENT = “Low Hope & Low Life Satisfaction”",
									],
									style: "paragraph"
								},
								{
									text: "The percentage of your students who fall into each quadrant are shown on the graph.",
									style: "paragraph"
								},
								{
									text: "The table below shows your students Resilience Score by Year Level and Gender, compared to Australian Norms (AN):",
									style: "paragraph"
								}
							]
						],
						columnGap: 20
					},
				]
			},
			{
				key: "healthy-mind",
				surveys: [
					"student-resilience",
					"short-resilience",
					"relationships-and-no-bullying",
					"IPE-Mi7",
					"JGI_NatureWellbeingUpper",
					"learning-curve-wellbeing"
				],
				shade: "#c0dad9",
				graph: "scatter",
				scales: ["GAD-2", "PHQ-2"],
				xMeasure: "Depressed",
				xMax: 6,
				yMeasure: "Anxious",
				yMax: 6,
				quarters: {
					leftbottom: "HEALTHY MIND",
					rightbottom: "DEPRESSED",
					lefttop: "ANXIOUS",
					righttop: "ANXIOUS & DEPRESSED",
				},
				title: "Healthy Mind Score",
				subtitle: "Anxiety and Depression: GAD-2 and PHQ-2",
				description:
					"This scatterplot represents each respondent with a single dot. The position of each dot indicates each students reported symptoms of either anxiety, depression, or both anxiety and depression, or the absence of these symptoms, suggesting a healthy mind.",
				explainer: [
					{
						columns: [
							[
								{
									text: "Anxiety and depression are commonly experienced by young people. ",
									style: "paragraph"
								},
								{
									text: "On this scatterplot each student is represented with a single dot. The position of each dot indicates each student’s reported symptoms of either anxiety, depression, or both anxiety and depression, or the absence of these symptoms, suggesting a “healthy mind”.",
									style: "paragraph"
								},
							],
							[
								{
									text: "In other words, appropriate thresholds allow students to be categorised by whether they are:",
									style: "paragraph"
								},
								{
									ul: [
										"ANXIOUS (but not depressed)",
										"DEPRESSED (but not anxious)",
										"both ANXIOUS & DEPRESSED",
										"HEALTHY MIND (neither anxious nor depressed)"
									],
									style: "paragraph"
								},
								{
									text: "The percentage of your students who fall into each quadrant are shown on the graph.",
									style: "paragraph"
								},
								{
									text: "The table below shows your students Healthy Mind Score by Year Level and Gender, compared to Australian Norms (AN):",
									style: "paragraph"
								}
							]
						],
						columnGap: 20
					}
				]
			},
			{
				key: "ready-to-learn",
				surveys: [
					"student-resilience",
					"short-resilience",
					"relationships-and-no-bullying",
				],
				shade: "#fedb4a",
				graph: "scatter",
				scales: ["GAD-2", "CSI-4"],
				xMeasure: "Disengaged (from problem solving)",
				xMax: 8,
				yMeasure: "Anxious",
				yMax: 6,
				quarters: {
					leftbottom: "READY TO LEARN",
					rightbottom: "DISENGAGED",
					lefttop: "ANXIOUS",
					righttop: "ANXIOUS & DISENGAGED",
				},
				title: "Ready to Learn Score",
				subtitle: "Anxiety and Coping: GAD-2 and CSI-4",
				description:
					"This scatterplot represents each respondent with a single dot. The position of each dot indicates each respondent reported symptoms of either anxiety, disengagement from problem-solving, or both anxiety and disengagement from problem-solving, or the absence these symptoms, suggesting a readiness to learn.",
				explainer: [
					{
						columns: [
							[
								{
									text: "The many ways that students respond negatively to challenging situations fall into two broad classes: anxiety and disengagement from problem solving. ",
									style: "paragraph"
								},
								{
									text: "On this scatterplot each student is represented with a single dot. The position of each dot indicates each student’s reported symptoms of either anxiety, disengagement from problem solving, or both anxiety and disengagement from problem solving, or the absence of these symptoms, suggesting a “Ready to Learn” mindset. ",
									style: "paragraph"
								},
							],
							[
								{
									text: "In other words, appropriate thresholds allow students to be categorised by whether they are:",
									style: "paragraph"
								},
								{
									ul: [
										"ANXIOUS (but not disengaged from problem solving)",
										"DISENGAGED from problem solving (but not anxious)",
										"both ANXIOUS & DISENGAGED from problem solving",
										"READY TO LEARN (neither anxious nor disengaged from problem solving)"
									],
									style: "paragraph"
								},
								{
									text: "The percentage of your students who fall into each quadrant are shown on the graph.",
									style: "paragraph"
								},
								{
									text: "The table below shows your students Ready to Learn Score by Year Level and Gender, compared to Australian Norms (AN):",
									style: "paragraph"
								}
							]
						],
						columnGap: 20
					}
				]
			},
			{
				key: "IINS",
				graph: "stacked",
				surveys: ["student-resilience", "phillips-foundation"],
				classes: [
					{
						id: 3,
						label: "STRONGER connection to nature",
						colour: "#43a047",
					},
					{
						id: 2,
						label: "MODERATE connection to nature",
						colour: "#fdd835",
					},
					{
						id: 1,
						label: "WEAKER connection to nature",
						colour: "#f4511e",
					},
				],
				threshold: 3,
				classify: function (x) {
					if (x >= 4) {
						return 3;
					} else if (x == 3) {
						return 2;
					} else {
						return 1;
					}
				},
				title: "Connection to Nature",
				subtitle: "Illustrated Inclusion of Nature in Self Scale (IINS)",
				description:
					"This graph shows the varying degrees of connection to nature reported among your respondents.",
				appendix: {
					question: "How connected are you with nature? (picture response)",
				},
			},
		],
		staff: [
			{
				key: "cantril",
				graph: "stacked",
				surveys: ["school-staff-resilience"],
				classes: [
					{
						id: 8,
						label: "8 (best)",
						colour: "#388e3c",
					},
					{
						id: 7,
						label: "7",
						colour: "#43a047",
					},
					{
						id: 6,
						label: "6",
						colour: "#7cb342",
					},
					{
						id: 5,
						label: "5",
						colour: "#c0ca33",
					},
					{
						id: 4,
						label: "4",
						colour: "#fdd835",
					},
					{
						id: 3,
						label: "3",
						colour: "#ffb300",
					},
					{
						id: 2,
						label: "2",
						colour: "#fb8c00",
					},
					{
						id: 1,
						label: "1 (worst)",
						colour: "#f4511e",
					},
				],
				threshold: 5,
				classify: function (x) {
					return Number(x);
				},
				title: "Overall Life Satisfaction",
				subtitle: "Cantril Self Anchoring Scale (Cantril Ladder)",
				description:
					"This graph shows the reported levels of overall life satisfaction among your staff.",
				appendix: {
					question:
						"Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents the best possible life for YOU and the bottom represents the worst possible life for YOU. On which step of the ladder do you feel YOU stand at this time?",
				},
			},
			{
				key: "cantril2",
				graph: "stacked",
				default_hVar: "agegroup",
				surveys: ["school-staff-resilience"],
				title: "Job Satisfaction",
				isNew: false,
				firstYear: 2019,
				classes: [
					{
						id: 8,
						label: "8 (best)",
						colour: "#388e3c",
					},
					{
						id: 7,
						label: "7",
						colour: "#43a047",
					},
					{
						id: 6,
						label: "6",
						colour: "#7cb342",
					},
					{
						id: 5,
						label: "5",
						colour: "#c0ca33",
					},
					{
						id: 4,
						label: "4",
						colour: "#fdd835",
					},
					{
						id: 3,
						label: "3",
						colour: "#ffb300",
					},
					{
						id: 2,
						label: "2",
						colour: "#fb8c00",
					},
					{
						id: 1,
						label: "1 (worst)",
						colour: "#f4511e",
					},
				],
				threshold: 5,
				classify: function (x) {
					return Number(x);
				},
				title: "Overall Job Satisfaction",
				subtitle: "Cantril Self Anchoring Scale (Cantril Ladder)",
				description:
					"This graph shows the reported levels of overall job satisfaction among your staff.",
				appendix: {
					question:
						"Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR JOB and the bottom represents very dissatisfied with YOUR JOB. On which step of the ladder do you feel you stand at this time?",
				},
			},
			{
				key: "cantril3",
				graph: "stacked",
				surveys: ["school-staff-resilience"],
				classes: [
					{
						id: 8,
						label: "8 (best)",
						colour: "#388e3c",
					},
					{
						id: 7,
						label: "7",
						colour: "#43a047",
					},
					{
						id: 6,
						label: "6",
						colour: "#7cb342",
					},
					{
						id: 5,
						label: "5",
						colour: "#c0ca33",
					},
					{
						id: 4,
						label: "4",
						colour: "#fdd835",
					},
					{
						id: 3,
						label: "3",
						colour: "#ffb300",
					},
					{
						id: 2,
						label: "2",
						colour: "#fb8c00",
					},
					{
						id: 1,
						label: "1 (worst)",
						colour: "#f4511e",
					},
				],
				threshold: 5,
				classify: function (x) {
					return Number(x);
				},
				title: "Overall Workplace Satisfaction",
				subtitle: "Cantril Self Anchoring Scale (Cantril Ladder)",
				description:
					"This graph shows the reported levels of overall workplace satisfaction among your staff.",
				appendix: {
					question:
						"Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR WORKPLACE and the bottom represents very dissatisfied with YOUR WORKPLACE. On which step of the ladder do you feel you stand at this time?",
				},
			},
			{
				key: "AHS",
				graph: "stacked",
				surveys: ["school-staff-resilience"],
				classes: [
					{
						id: 6,
						label: "All",
						colour: "#43a047",
					},
					{
						id: 5,
						label: "Most",
						colour: "#7cb342",
					},
					{
						id: 4,
						label: "Lots",
						colour: "#c0ca33",
					},
					{
						id: 3,
						label: "Some",
						colour: "#fdd835",
					},
					{
						id: 2,
						label: "Little",
						colour: "#ffb300",
					},
					{
						id: 1,
						label: "None",
						colour: "#f4511e",
					},
				],
				threshold: 4,
				classify: function (x) {
					if (x >= 15) {
						return 6;
					} else if (x >= 13) {
						return 5;
					} else if (x >= 10) {
						return 4;
					} else if (x >= 8) {
						return 3;
					} else if (x >= 6) {
						return 2;
					} else {
						return 1;
					}
				},
				title: "Hope",
				subtitle: "Adult’s Hope Scale (AHS)",
				description:
					"This graph shows the varying degrees of hope reported among your staff.",
				appendix: {
					question: "In most situations,",
					options: [
						"I can think of many ways to get out of a bad situation.",
						"I believe there are lots of ways around any problem.",
						"I can think of many ways to get the things in life that are most important to me.",
						"Even when others get discouraged, I know I can find a way to solve the problem.",
					],
				},
			},
			{
				key: "healthy-mind",
				surveys: ["school-staff-resilience"],
				shade: "#c0dad9",
				graph: "scatter",
				scales: ["GAD-2", "PHQ-2"],
				xMeasure: "Depressed",
				xMax: 6,
				yMeasure: "Anxious",
				yMax: 6,
				quarters: {
					leftbottom: "HEALTHY MIND",
					rightbottom: "DEPRESSED",
					lefttop: "ANXIOUS",
					righttop: "ANXIOUS & DEPRESSED",
				},
				classes: [
					{
						id: 4,
						label: "Normal",
						colour: "#199750",
					},
					{
						id: 3,
						label: "Mild",
						colour: "#66bc62",
					},
					{
						id: 2,
						label: "Moderate",
						colour: "#f36c42",
					},
					{
						id: 1,
						label: "Severe",
						colour: "#d62f26",
					},
				],
				threshold: 3,
				classify: function (x) {
					if (x <= 2) {
						return 4;
					} else if (x <= 5) {
						return 3;
					} else if (x <= 8) {
						return 2;
					} else {
						return 1;
					}
				},
				title: "Anxiety and Depression",
				subtitle: "Patient Health Questionnaire (healthy-mind)",
				description:
					"This scatterplot represents each staff with a single dot. The position of each dot indicates each staff reported symptoms of either anxiety, depression, or both anxiety and depression, or the absence of these symptoms, suggesting a healthy mind.",
				appendix: {
					question:
						"Over the last 2 weeks, how often have you been bothered by the following problems?",
					options: [
						"Feeling nervous, anxious, or on edge.",
						"Not being able to stop or control worrying.",
						"Feeling down, depressed or hopeless.",
						"Little interest or pleasure in doing things.",
					],
				},
				explainer: [
					{
						columns: [
							[
								{
									text: "Anxiety and depression are commonly experienced by people. ",
									style: "paragraph"
								},
								{
									text: "On this scatterplot each staff is represented with a single dot. The position of each dot indicates each staff member’s reported symptoms of either anxiety, depression, or both anxiety and depression, or the absence of these symptoms, suggesting a “healthy mind”.",
									style: "paragraph"
								},
							],
							[
								{
									text: "In other words, appropriate thresholds allow staff to be categorised by whether they are:",
									style: "paragraph"
								},
								{
									ul: [
										"ANXIOUS (but not depressed)",
										"DEPRESSED (but not anxious)",
										"both ANXIOUS & DEPRESSED",
										"HEALTHY MIND (neither anxious nor depressed)"
									],
									style: "paragraph"
								},
								{
									text: "The percentage of your staff who fall into each quadrant are shown on the graph.",
									style: "paragraph"
								},
								{
									text: "The table below shows your staff Healthy Mind Score by Age Group and Gender, compared to Australian Norms (AN):",
									style: "paragraph"
								}
							]
						],
						columnGap: 20
					}
				]
			},
			{
				key: "PHQ-2",
				graph: "stacked",
				surveys: ["school-staff-resilience"],
				classes: [
					{
						id: 2,
						label: "Not Depressed",
						colour: "#66bc62",
					},
					{
						id: 1,
						label: "Depressed",
						colour: "#f36c42",
					},
				],
				threshold: 2,
				classify: function (x) {
					if (x >= 3) {
						return 1;
					} else {
						return 2;
					}
				},
				title: "Depression",
				subtitle: "Patient Health Questionnaire (PHQ-2)",
				description:
					"This graph shows the percentage of your staff who report symptoms of depression.",
				appendix: {
					question:
						"Over the last 2 weeks, how often have you been bothered by the following problems?",
					options: [
						"Feeling down, depressed or hopeless.",
						"Little interest or pleasure in doing things.",
					],
				},
			},
			{
				key: "GAD-2",
				graph: "stacked",
				surveys: ["school-staff-resilience"],
				classes: [
					{
						id: 2,
						label: "Not Anxious",
						colour: "#66bc62",
					},
					{
						id: 1,
						label: "Anxious",
						colour: "#f36c42",
					},
				],
				threshold: 2,
				classify: function (x) {
					if (x >= 3) {
						return 1;
					} else {
						return 2;
					}
				},
				title: "Anxiety",
				subtitle: "Generalized Anxiety Disorder (GAD-2)",
				description:
					"This graph shows the percentage of your staff who report symptoms of anxiety.",
				appendix: {
					question:
						"Over the last 2 weeks, how often have you been bothered by the following problems?",
					options: [
						"Feeling nervous, anxious, or on edge.",
						"Not being able to stop or control worrying.",
					],
				},
				
			},
		],
		parent: [
			{
				key: "cantril5",
				graph: "stacked",
				classes: [
					{
						id: 8,
						label: "8 (best)",
						colour: "#388e3c",
					},
					{
						id: 7,
						label: "7",
						colour: "#43a047",
					},
					{
						id: 6,
						label: "6",
						colour: "#7cb342",
					},
					{
						id: 5,
						label: "5",
						colour: "#c0ca33",
					},
					{
						id: 4,
						label: "4",
						colour: "#fdd835",
					},
					{
						id: 3,
						label: "3",
						colour: "#ffb300",
					},
					{
						id: 2,
						label: "2",
						colour: "#fb8c00",
					},
					{
						id: 1,
						label: "1 (worst)",
						colour: "#f4511e",
					},
				],
				threshold: 5,
				classify: function (x) {
					return Number(x);
				},
				title: "Overall Life Satisfaction",
				subtitle: "Cantril Self Anchoring Scale (Cantril Ladder)",
				description:
					"This graph shows the reported levels of overall life satisfaction among the parents/caregivers .",
				appendix: {
					question:
						"Please see the ladder below. There are 8 steps on this ladder. How satisfied are you currently with your parenting/caregiving life on a scale of 1 to 8? where Step 1. is “very dissatisfied” and Step 8. is “extremely satisfied”?",
				},
			},
			{
				key: "cantril4",
				graph: "stacked",
				classes: [
					{
						id: 8,
						label: "8 (best)",
						colour: "#388e3c",
					},
					{
						id: 7,
						label: "7",
						colour: "#43a047",
					},
					{
						id: 6,
						label: "6",
						colour: "#7cb342",
					},
					{
						id: 5,
						label: "5",
						colour: "#c0ca33",
					},
					{
						id: 4,
						label: "4",
						colour: "#fdd835",
					},
					{
						id: 3,
						label: "3",
						colour: "#ffb300",
					},
					{
						id: 2,
						label: "2",
						colour: "#fb8c00",
					},
					{
						id: 1,
						label: "1 (worst)",
						colour: "#f4511e",
					},
				],
				threshold: 5,
				classify: function (x) {
					return Number(x);
				},
				title: "Satisfaction with Child Wellbeing",
				subtitle: "Cantril Self Anchoring Scale (Cantril Ladder)",
				description:
					"This graph shows the reported levels of overall satisfaction of parents/caregivers  with their childs' wellbeing.",
				appendix: {
					question:
						"Please see the ladder below. There are 8 steps on this ladder. How satisfied are you with your child’s life at school on a scale of 1 to 8? where Step 1. is “very dissatisfied” and Step 8. is “extremely satisfied”?",
				},
			},
			{
				key: "cantril6",
				graph: "stacked",
				classes: [
					{
						id: 8,
						label: "8 (best)",
						colour: "#388e3c",
					},
					{
						id: 7,
						label: "7",
						colour: "#43a047",
					},
					{
						id: 6,
						label: "6",
						colour: "#7cb342",
					},
					{
						id: 5,
						label: "5",
						colour: "#c0ca33",
					},
					{
						id: 4,
						label: "4",
						colour: "#fdd835",
					},
					{
						id: 3,
						label: "3",
						colour: "#ffb300",
					},
					{
						id: 2,
						label: "2",
						colour: "#fb8c00",
					},
					{
						id: 1,
						label: "1 (worst)",
						colour: "#f4511e",
					},
				],
				threshold: 5,
				classify: function (x) {
					return Number(x);
				},
				title: "Overall School Satisfaction",
				subtitle: "Cantril Self Anchoring Scale (Cantril Ladder)",
				description:
					"This graph shows the reported levels of overall school satisfaction among the parents/caregivers .",
				appendix: {
					question:
						"On a scale of 1 to 8, how satisfied are you with THIS SCHOOL?",
				},
			},
			{
				key: "healthy-mind",
				shade: "#c0dad9",
				graph: "scatter",
				scales: ["GAD-2", "PHQ-2"],
				xMeasure: "Depressed",
				xMax: 6,
				yMeasure: "Anxious",
				yMax: 6,
				quarters: {
					leftbottom: "HEALTHY MIND",
					rightbottom: "DEPRESSED",
					lefttop: "ANXIOUS",
					righttop: "ANXIOUS & DEPRESSED",
				},
				classes: [
					{
						id: 4,
						label: "Normal",
						colour: "#199750",
					},
					{
						id: 3,
						label: "Mild",
						colour: "#66bc62",
					},
					{
						id: 2,
						label: "Moderate",
						colour: "#f36c42",
					},
					{
						id: 1,
						label: "Severe",
						colour: "#d62f26",
					},
				],
				threshold: 3,
				classify: function (x) {
					if (x <= 2) {
						return 4;
					} else if (x <= 5) {
						return 3;
					} else if (x <= 8) {
						return 2;
					} else {
						return 1;
					}
				},
				title: "Anxiety and Depression",
				subtitle: "Patient Health Questionnaire (healthy-mind)",
				description:
					"This scatterplot represents each parent with a single dot. The position of each dot indicates each parents reported symptoms of either anxiety, depression, or both anxiety and depression, or the absence of these symptoms, suggesting a healthy mind.",
				appendix: {
					question:
						"Over the last 2 weeks, how often have you been bothered by the following problems?",
					options: [
						"Feeling nervous, anxious, or on edge.",
						"Not being able to stop or control worrying.",
						"Feeling down, depressed or hopeless.",
						"Little interest or pleasure in doing things.",
					],
				},
				explainer: [
					{
						columns: [
							[
								{
									text: "Anxiety and depression are commonly experienced by people. ",
									style: "paragraph"
								},
								{
									text: "On this scatterplot each parent is represented with a single dot. The position of each dot indicates each parent’s reported symptoms of either anxiety, depression, or both anxiety and depression, or the absence of these symptoms, suggesting a “healthy mind”.",
									style: "paragraph"
								},
							],
							[
								{
									text: "In other words, appropriate thresholds allow parents to be categorised by whether they are:",
									style: "paragraph"
								},
								{
									ul: [
										"ANXIOUS (but not depressed)",
										"DEPRESSED (but not anxious)",
										"both ANXIOUS & DEPRESSED",
										"HEALTHY MIND (neither anxious nor depressed)"
									],
									style: "paragraph"
								},
								{
									text: "The percentage of your parents who fall into each quadrant are shown on the graph.",
									style: "paragraph"
								},
								{
									text: "The table below shows your parents Healthy Mind Score by Age Group and Gender, compared to Australian Norms (AN):",
									style: "paragraph"
								}
							]
						],
						columnGap: 20
					}
				]
			},
			{
				key: "PHQ-2",
				graph: "stacked",
				classes: [
					{
						id: 2,
						label: "Not Depressed",
						colour: "#66bc62",
					},
					{
						id: 1,
						label: "Depressed",
						colour: "#f36c42",
					},
				],
				threshold: 2,
				classify: function (x) {
					if (x >= 3) {
						return 1;
					} else {
						return 2;
					}
				},
				title: "Depression",
				subtitle: "Patient Health Questionnaire (PHQ-2)",
				description:
					"This graph shows the percentage of your staff who report symptoms of depression.",
				appendix: {
					question:
						"Over the last 2 weeks, how often have you been bothered by the following problems?",
					options: [
						"Feeling down, depressed or hopeless.",
						"Little interest or pleasure in doing things.",
					],
				},
			},
			{
				key: "GAD-2",
				graph: "stacked",
				classes: [
					{
						id: 2,
						label: "Not Anxious",
						colour: "#66bc62",
					},
					{
						id: 1,
						label: "Anxious",
						colour: "#f36c42",
					},
				],
				threshold: 2,
				classify: function (x) {
					if (x >= 3) {
						return 1;
					} else {
						return 2;
					}
				},
				title: "Anxiety",
				subtitle: "Generalized Anxiety Disorder (GAD-2)",
				description:
					"This graph shows the percentage of parents/caregivers  who report symptoms of anxiety.",
				appendix: {
					question:
						"Over the last 2 weeks, how often have you been bothered by the following problems?",
					options: [
						"Feeling nervous, anxious, or on edge.",
						"Not being able to stop or control worrying.",
					],
				},
			},
		],
		employee: [
			{
				key: "cantril",
				graph: "stacked",
				surveys: ["workplace-resilience"],
				classes: [
					{
						id: 8,
						label: "8 (best)",
						colour: "#388e3c",
					},
					{
						id: 7,
						label: "7",
						colour: "#43a047",
					},
					{
						id: 6,
						label: "6",
						colour: "#7cb342",
					},
					{
						id: 5,
						label: "5",
						colour: "#c0ca33",
					},
					{
						id: 4,
						label: "4",
						colour: "#fdd835",
					},
					{
						id: 3,
						label: "3",
						colour: "#ffb300",
					},
					{
						id: 2,
						label: "2",
						colour: "#fb8c00",
					},
					{
						id: 1,
						label: "1 (worst)",
						colour: "#f4511e",
					},
				],
				threshold: 5,
				classify: function (x) {
					return Number(x);
				},
				title: "Overall Life Satisfaction",
				subtitle: "Cantril Self Anchoring Scale (Cantril Ladder)",
				description:
					"This graph shows the reported levels of overall life satisfaction among your employees.",
				appendix: {
					question:
						"Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents the best possible life for YOU and the bottom represents the worst possible life for YOU. On which step of the ladder do you feel YOU stand at this time?",
				},
			},
			{
				key: "cantril2",
				graph: "stacked",
				default_hVar: "agegroup",
				surveys: ["workplace-resilience"],
				title: "Job Satisfaction",
				isNew: false,
				firstYear: 2019,
				classes: [
					{
						id: 8,
						label: "8 (best)",
						colour: "#388e3c",
					},
					{
						id: 7,
						label: "7",
						colour: "#43a047",
					},
					{
						id: 6,
						label: "6",
						colour: "#7cb342",
					},
					{
						id: 5,
						label: "5",
						colour: "#c0ca33",
					},
					{
						id: 4,
						label: "4",
						colour: "#fdd835",
					},
					{
						id: 3,
						label: "3",
						colour: "#ffb300",
					},
					{
						id: 2,
						label: "2",
						colour: "#fb8c00",
					},
					{
						id: 1,
						label: "1 (worst)",
						colour: "#f4511e",
					},
				],
				threshold: 5,
				classify: function (x) {
					return Number(x);
				},
				title: "Overall Job Satisfaction",
				subtitle: "Cantril Self Anchoring Scale (Cantril Ladder)",
				description:
					"This graph shows the reported levels of overall job satisfaction among your employees.",
				appendix: {
					question:
						"Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR JOB and the bottom represents very dissatisfied with YOUR JOB. On which step of the ladder do you feel you stand at this time?",
				},
			},
			{
				key: "cantril3",
				graph: "stacked",
				surveys: ["workplace-resilience"],
				classes: [
					{
						id: 8,
						label: "8 (best)",
						colour: "#388e3c",
					},
					{
						id: 7,
						label: "7",
						colour: "#43a047",
					},
					{
						id: 6,
						label: "6",
						colour: "#7cb342",
					},
					{
						id: 5,
						label: "5",
						colour: "#c0ca33",
					},
					{
						id: 4,
						label: "4",
						colour: "#fdd835",
					},
					{
						id: 3,
						label: "3",
						colour: "#ffb300",
					},
					{
						id: 2,
						label: "2",
						colour: "#fb8c00",
					},
					{
						id: 1,
						label: "1 (worst)",
						colour: "#f4511e",
					},
				],
				threshold: 5,
				classify: function (x) {
					return Number(x);
				},
				title: "Overall Workplace Satisfaction",
				subtitle: "Cantril Self Anchoring Scale (Cantril Ladder)",
				description:
					"This graph shows the reported levels of overall workplace satisfaction among your employees.",
				appendix: {
					question:
						"Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR WORKPLACE and the bottom represents very dissatisfied with YOUR WORKPLACE. On which step of the ladder do you feel you stand at this time?",
				},
			},
			{
				key: "PHQ-2",
				graph: "stacked",
				surveys: ["workplace-resilience"],
				classes: [
					{
						id: 2,
						label: "Not Depressed",
						colour: "#66bc62",
					},
					{
						id: 1,
						label: "Depressed",
						colour: "#f36c42",
					},
				],
				threshold: 2,
				classify: function (x) {
					if (x >= 3) {
						return 1;
					} else {
						return 2;
					}
				},
				title: "Depression",
				subtitle: "Patient Health Questionnaire (PHQ-2)",
				description:
					"This graph shows the percentage of your employees who report symptoms of depression.",
				appendix: {
					question:
						"Over the last 2 weeks, how often have you been bothered by the following problems?",
					options: [
						"Feeling down, depressed or hopeless.",
						"Little interest or pleasure in doing things.",
					],
				},
			},
			{
				key: "AHS",
				graph: "stacked",
				surveys: ["workplace-resilience"],
				classes: [
					{
						id: 6,
						label: "All",
						colour: "#43a047",
					},
					{
						id: 5,
						label: "Most",
						colour: "#7cb342",
					},
					{
						id: 4,
						label: "Lots",
						colour: "#c0ca33",
					},
					{
						id: 3,
						label: "Some",
						colour: "#fdd835",
					},
					{
						id: 2,
						label: "Little",
						colour: "#ffb300",
					},
					{
						id: 1,
						label: "None",
						colour: "#f4511e",
					},
				],
				threshold: 4,
				classify: function (x) {
					if (x >= 15) {
						return 6;
					} else if (x >= 13) {
						return 5;
					} else if (x >= 10) {
						return 4;
					} else if (x >= 8) {
						return 3;
					} else if (x >= 6) {
						return 2;
					} else {
						return 1;
					}
				},
				title: "Hope",
				subtitle: "Adult’s Hope Scale (AHS)",
				description:
					"This graph shows the varying degrees of hope reported among your employees.",
				appendix: {
					question: "In most situations,",
					options: [
						"I can think of many ways to get out of a bad situation.",
						"I believe there are lots of ways around any problem.",
						"I can think of many ways to get the things in life that are most important to me.",
						"Even when others get discouraged, I know I can find a way to solve the problem.",
					],
				},
			},
			{
				key: "healthy-mind",
				shade: "#c0dad9",
				graph: "scatter",
				surveys: ["workplace-resilience"],
				scales: ["GAD-2", "PHQ-2"],
				xMeasure: "Depressed",
				xMax: 6,
				yMeasure: "Anxious",
				yMax: 6,
				quarters: {
					leftbottom: "HEALTHY MIND",
					rightbottom: "DEPRESSED",
					lefttop: "ANXIOUS",
					righttop: "ANXIOUS & DEPRESSED",
				},
				classes: [
					{
						id: 4,
						label: "Normal",
						colour: "#199750",
					},
					{
						id: 3,
						label: "Mild",
						colour: "#66bc62",
					},
					{
						id: 2,
						label: "Moderate",
						colour: "#f36c42",
					},
					{
						id: 1,
						label: "Severe",
						colour: "#d62f26",
					},
				],
				threshold: 3,
				classify: function (x) {
					if (x <= 2) {
						return 4;
					} else if (x <= 5) {
						return 3;
					} else if (x <= 8) {
						return 2;
					} else {
						return 1;
					}
				},
				title: "Anxiety and Depression",
				subtitle: "Patient Health Questionnaire (healthy-mind)",
				description:
					"This scatterplot represents each employee with a single dot. The position of each dot indicates each employees reported symptoms of either anxiety, depression, or both anxiety and depression, or the absence of these symptoms, suggesting a healthy mind.",
				appendix: {
					question:
						"Over the last 2 weeks, how often have you been bothered by the following problems?",
					options: [
						"Feeling nervous, anxious, or on edge.",
						"Not being able to stop or control worrying.",
						"Feeling down, depressed or hopeless.",
						"Little interest or pleasure in doing things.",
					],
				},
				explainer: [
					{
						columns: [
							[
								{
									text: "Anxiety and depression are commonly experienced by people. ",
									style: "paragraph"
								},
								{
									text: "On this scatterplot each employee is represented with a single dot. The position of each dot indicates each employee’s reported symptoms of either anxiety, depression, or both anxiety and depression, or the absence of these symptoms, suggesting a “healthy mind”.",
									style: "paragraph"
								},
							],
							[
								{
									text: "In other words, appropriate thresholds allow employees to be categorised by whether they are:",
									style: "paragraph"
								},
								{
									ul: [
										"ANXIOUS (but not depressed)",
										"DEPRESSED (but not anxious)",
										"both ANXIOUS & DEPRESSED",
										"HEALTHY MIND (neither anxious nor depressed)"
									],
									style: "paragraph"
								},
								{
									text: "The percentage of your employees who fall into each quadrant are shown on the graph.",
									style: "paragraph"
								},
								{
									text: "The table below shows your employees Healthy Mind Score by Age Group and Gender, compared to Australian Norms (AN):",
									style: "paragraph"
								}
							]
						],
						columnGap: 20
					}
				]
			},
			{
				key: "GAD-2",
				graph: "stacked",
				surveys: ["workplace-resilience"],
				classes: [
					{
						id: 2,
						label: "Not Anxious",
						colour: "#66bc62",
					},
					{
						id: 1,
						label: "Anxious",
						colour: "#f36c42",
					},
				],
				threshold: 2,
				classify: function (x) {
					if (x >= 3) {
						return 1;
					} else {
						return 2;
					}
				},
				title: "Anxiety",
				subtitle: "Generalized Anxiety Disorder (GAD-2)",
				description:
					"This graph shows the percentage of your employees who report symptoms of anxiety.",
				appendix: {
					question:
						"Over the last 2 weeks, how often have you been bothered by the following problems?",
					options: [
						"Feeling nervous, anxious, or on edge.",
						"Not being able to stop or control worrying.",
					],
				},
			},
		]
	},
	copyright = {
		"student-resilience": [
			{
				text: "C",
				font: "FontAwesome",
				fontSize: 7,
			},
			" Resilient Youth Australia Pty Ltd, 2024 (ABN 19 636 065 711). All Rights Reserved.\n",
			{
				text: "Resilient Youth",
			},
			{
				text: "T",
				font: "FontAwesome",
			},
			" is a Registered Trademark of Resilient Youth Australia Pty Ltd.",
		],
		"phillips-foundation": [
			{
				text: "C",
				font: "FontAwesome",
				fontSize: 7,
			},
			" 2024. All Rights Reserved.\n",
		],
		"workplace-resilience": [ 
			{ "text": "C", "font": "FontAwesome", "fontSize": 7 }, 
			" Lead-Well Coaching & Consulting 2024. All Rights Reserved.\n"
		]
	},
	appendix = {
		"student-resilience": {},
		"phillips-foundation": {
			image: images["toms-appendix-image"]
		},
		"workplace-resilience": {},
	},
	background = {
		"student-resilience": {
			horizontalBanner: {
				image: banners["horizontalBanner"]
			},
		}
	};
let mentalhealths = [
	{
		group: "student",
		surveys: ["student-resilience"],
		slug: "mentalhealth",
		title: "Mental Health",
		hVar: "grade",
		purpose:
			"To see at a glance the mental health status of students based on different gold standard instruments.",
		askYourself:
			"On which of the gold standard instruments are students of this cohort having trouble with?",
		contents:
			"Stacked Bar graphs showing the percentage of students within each value of the gold standard scale (by gender and year level).",
		cover: cover["student-resilience"],
		frontmatter: frontmatter["student-resilience"],
		backmatter: [],
		copyright: copyright["student-resilience"],
		pages: pages["student"].filter(pg => !pg.hasOwnProperty('surveys') || pg.surveys.includes("student-resilience")).map((pg) => ({ ...pg, group: "student" })),
		background: background["student-resilience"].horizontalBanner.image
	},
	{
		group: "student",
		surveys: ["phillips-foundation"],
		slug: "annualoutcomes",
		title: "TOMS",
		hVar: "grade",
		purpose:
			"To see at a glance the mental health status of students based on different gold standard instruments.",
		askYourself:
			"On which of the gold standard instruments are students of this cohort having trouble with?",
		contents:
			"Stacked Bar graphs showing the percentage of students within each value of the gold standard scale (by gender and year level).",
		cover: cover["phillips-foundation"],
		//frontmatter: frontmatter["phillips-foundation"],
		backmatter: [],
		copyright: copyright["phillips-foundation"],
		pages: pages["student"].filter(pg => !pg.hasOwnProperty('surveys') || pg.surveys.includes("phillips-foundation")).map((pg) => ({ ...pg, group: "student" })),
		appendixImage:appendix["phillips-foundation"].image,
	},
	{
		group: "employee",
		surveys: ["workplace-resilience"],
		slug: "mentalhealth",
		title: "Mental Health",
		hVar: "agegroup",
		purpose:
			"To see at a glance the mental health status of employees based on different gold standard instruments.",
		askYourself:
			"On which of the gold standard instruments are employees of this cohort having trouble with?",
		contents:
			"Stacked Bar graphs showing the percentage of employees within each value of the gold standard scale (by gender and age group).",
		cover: cover["workplace-resilience"],
		frontmatter: frontmatter["workplace-resilience"],
		backmatter: [],
		copyright: copyright["workplace-resilience"],
		pages: pages["employee"].filter(pg => !pg.hasOwnProperty('surveys') || pg.surveys.includes("workplace-resilience")).map((pg) => ({ ...pg, group: "employee" })),
	},
];

export default mentalhealths;